<template>
  <div class="schoolOrderStatisticsDetails">
    <div class="search_sList">
      <div class="top_opera">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="100px">
          <el-form-item label="学校名称">
            <el-input @blur="changeSchName" v-model="formInline.tenantName" placeholder="学校名称"></el-input>
          </el-form-item>
          <el-form-item label="学生单价">
            <div class="db-mon">
              <el-input @blur="getSchoolDetail" v-model="formInline.price" placeholder="学生单价"></el-input>元
            </div>
          </el-form-item>
        </el-form>
        <div class="btn_area">
          <el-button class="mainBtn" @click="exportEvent" plain>导出</el-button>
        </div>
      </div>
    </div>
    <div class="table_sList">
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%"
        height="550"
        align="center"
        row-key='id'>
        <el-table-column
          v-for="(item, index) in columnList"
          :key="index"
          :prop="item.prop"
          :label="item.label ? item.label : '/'"
          :width="item.width"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row[scope.column.property] == null">/</span>
            <template v-else-if="scope.column.property == 'tenantName'">
              <el-tooltip
                :content="scope.row[scope.column.property]"
                placement="top"
                effect="light"
                popper-class="pubToop"
                v-if="scope.row[scope.column.property] != '总计'"
              >
                <div class="ellipsis">{{ scope.row[scope.column.property] }}</div>
              </el-tooltip>
              <span v-else>总计</span>
            </template>
            <span v-else-if="scope.column.property == 'stationNum'">
              <el-button v-if="scope.row.tenantName != '总计'" size="small" type="text"  @click="watchState(scope.row)">{{ scope.row.stationNum }}</el-button>
              <span v-else>{{ scope.row.stationNum }}</span>
            </span>
            <span v-else>
              {{ scope.row[scope.column.property] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 教学点订单统计详情 -->
    <dialogDiyNew :isShow="isShowStaDetail" :dialogDiyData="dialogStaDetailData"
    @dialogEvent="dialogStaDetailEvent">
      <div slot="dialogContent" class="sta_detail_area">
        <div class="opera_btn">
          <el-button class="mainBtn" @click="exportStaEvent" plain>导出</el-button>
        </div>
        <div class="table_sList">
          <el-table
            :data="tableStaData"
            stripe
            border
            style="width: 100%"
            height="550"
            align="center"
            row-key='id'>
            <el-table-column
              v-for="(item, index) in columnStaList"
              :key="index"
              :prop="item.prop"
              :label="item.label ? item.label : '/'"
              :width="item.width"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row[scope.column.property] == null">/</span>
                <template v-else-if="scope.column.property == 'stationName'">
                  <el-tooltip
                    :content="scope.row[scope.column.property]"
                    placement="top"
                    effect="light"
                    popper-class="pubToop"
                    v-if="scope.row[scope.column.property] != '总计'"
                  >
                    <div class="ellipsis">{{ scope.row[scope.column.property] }}</div>
                  </el-tooltip>
                  <span v-else>总计</span>
                </template>
                <span v-else>
                  {{ scope.row[scope.column.property] }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </dialogDiyNew>

    <handle
      :isShow="isShowDel"
      :handleText="handleText"
      @handlesEvent="doHandleEvent"
    >
      <div class="btn_area" slot="handle_btn">
        <el-button @click="doHandleEvent">关闭</el-button>
        <el-button type="primary" @click="deleStu">确定</el-button>
      </div>
    </handle>

    <dialogSubmit :isShowSub="isShowSub" @closeSub="closeSub"></dialogSubmit>
  </div>
</template>

<script>
const dialogDiyNew = () => import('@/components/commonModule/dialogDiyNew')
const handle = () => import('@/components/commonModule/handle')
const dialogSubmit = () => import('@/components/commonModule/dialogSubmit')
export default {
  name: 'schoolOrderStatisticsDetails',
  components: {
    dialogDiyNew,
    handle,
    dialogSubmit,
  },
  data () {
    return {
      formInline: {
        price: null,
        tenantId: null,
        tenantName: null,
        timeType: null
      },
      columnList: [
        {
          prop: "tenantName",
          label: "类目"
        },
        {
          prop: "price",
          label: "收费单价",
          width: 100
        },
        {
          prop: "receivableMoney",
          label: "应收金额",
          width: 100
        },
        {
          prop: "receiptMoney",
          label: "实收金额",
          width: 100
        },
        {
          prop: "freeMoney",
          label: "免费金额",
          width: 90
        },
        {
          prop: "unclearedMoney",
          label: "未结清金额"
        },
        {
          prop: "unpaidMoney",
          label: "未支付金额"
        },
        {
          prop: "studentNum",
          label: "总人数"
        },
        {
          prop: "learningOpenNum",
          label: "学习权限开通人数"
        },
        {
          prop: "payNum",
          label: "缴费人数",
          width: 100
        },
        {
          prop: "freeNum",
          label: "免费人数",
          width: 100
        },
        {
          prop: "unpaidNum",
          label: "未支付人数",
          width: 100
        },
        {
          prop: "stationNum",
          label: "教学点数量",
          width: 100
        }
      ],
      tableData: [],
      isShowStaDetail: false,
      dialogStaDetailData: {
        title: "教学点订单统计详情",
        sizeStyle: { width: "1500px", height: "750px" }
      },
      staForm: {
        stationId: null,
        price: null,
        tenantId: null,
        tenantName: null,
        timeType: null
      },
      tableStaData: [],
      columnStaList: [
      {
          prop: "stationName",
          label: "类目"
        },
        {
          prop: "price",
          label: "收费单价",
          width: 100
        },
        {
          prop: "receivableMoney",
          label: "应收金额",
          width: 100
        },
        {
          prop: "receiptMoney",
          label: "实收金额",
          width: 100
        },
        {
          prop: "freeMoney",
          label: "免费金额",
          width: 90
        },
        {
          prop: "unclearedMoney",
          label: "未结清金额"
        },
        {
          prop: "unpaidMoney",
          label: "未支付金额"
        },
        {
          prop: "studentNum",
          label: "总人数"
        },
        {
          prop: "learningOpenNum",
          label: "学习权限开通人数"
        },
        {
          prop: "payNum",
          label: "缴费人数",
          width: 100
        },
        {
          prop: "freeNum",
          label: "免费人数",
          width: 100
        },
        {
          prop: "unpaidNum",
          label: "未支付人数",
          width: 100
        }
      ],
      isShowSub: false,
      isShowDel: false,
      handleText: null,
      handleType: null,
    }
  },
  methods: {
    showEvent(obj) {
      Object.assign(this.formInline, obj)
      this.staForm.timeType = obj.timeType
      this.staForm.stationId = obj.stationId
      this.getSchoolDetail()
    },
    watchState(row) {
      this.staForm.tenantId = row.tenantId
      this.staForm.tenantName = row.tenantName
      this.dialogStaDetailEvent()
      this.getStationDetail()
    },
    dialogStaDetailEvent() {
      this.isShowStaDetail = !this.isShowStaDetail
    },
    getSchoolDetail() {
      this.$request.financialStatisticSchDetail(this.formInline).then(res => {
        if (res.data.code == 0) {
          this.tableData = res.data.data
        }
      })
    },
    changeSchName() {
      this.formInline.tenantId = null
      this.formInline.tenantName = this.formInline.tenantName || null
      this.getSchoolDetail()
    },
    getStationDetail() {
      this.$request.financialStatisticStaDetail(this.staForm).then(res => {
        if (res.data.code == 0) {
          this.tableStaData = res.data.data
        }
      })
    },
    exportEvent() {
      this.handleType = 'school'
      this.handleText = "是否要导出学校订单统计详情？"
      this.doHandleEvent()
    },
    exportStaEvent() {
      this.handleType = 'station'
      this.handleText = "是否要导出教学点订单统计详情？"
      this.doHandleEvent()
    },
    closeSub() {
      this.isShowSub = !this.isShowSub
    },
    doHandleEvent() {
      this.isShowDel = !this.isShowDel
    },
    deleStu() {
      if (this.handleType == 'school') {
        this.$request.exportSchoolOrderTotal(this.formInline).then(res => {
          if (res.data.code == 0) {
            this.Success(res.data.msg || '操作成功')
            this.doHandleEvent()
            this.closeSub()
          }
        })
      } else {
        this.$request.exportStationOrderTotal(this.staForm).then(res => {
          if (res.data.code == 0) {
            this.Success(res.data.msg || '操作成功')
            this.doHandleEvent()
            this.closeSub()
          }
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .schoolOrderStatisticsDetails {
    .search_sList {
      .db-mon {
        display: flex;
      }
      /deep/ .el-form-item {
        margin: 0;
      }
      /deep/ .el-input {
        margin-right: 10px;
      }
    }
    .table_sList {
      padding: 0 40px 10px;
      margin-top: 0;
    }
    .sta_detail_area {
      .opera_btn {
        text-align: right;
        padding: 20px 40px;
      }
    }
  }
</style>